import React from 'react'
import styled from 'styled-components'
import arqImage from '../images/ARQTraining.png'
import { Container, Primary } from '../components'

const Image = styled.img`
  width: 100%;
`

const textStyles = {
  fontFamily: 'Arial',
  padding: '.5em 0 0 0',
}

const Title = styled.span`
  ${textStyles};
  font-weight: 700;
  font-size: 18px;
`

const Italics = styled.span`
  ${textStyles};
  font-style: italic;
  font-size: 12px;
`

const P = styled.span`
  ${textStyles};
  font-size: 14px;
  text-align: center;
`

const ArqPage = () => {
  const onClick = () =>
    window.open(
      'https://forms.office.com/Pages/ResponsePage.aspx?id=kigPGeF0C0eVVka8ZF3n_wpTT7n8HuNAqK2J8MFWG3hUQTdPVzhDUUVOS0NMTDQ2OEZURDBUUFI3MC4u'
    )
  return (
    <Container direction="column">
      <Container maxWidth="500px" direction="column">
        <Image src={arqImage} />
        <Title>ARQ Training</Title>
        <Italics>Presented by the Q Sciences Innovation Team</Italics>
        <P>{`Are you interested in learning more about the Ambassador Resources Q (ARQ) App and how you can use it to help manage your Q Sciences business?`}</P>

        <P>{`The Q Sciences Innovtion Team will be offering ARQ training at Discover the Difference.`}</P>

        <P>{`When: Thursday, January 26, 2023 @ 6:00pm`}</P>
        <P
          style={{ padding: 0 }}
        >{`Where: Q Innovation Booth in the Experience Room`}</P>
      </Container>
      <Primary target="blank" onClick={onClick}>
        I AM INTERESTED
      </Primary>
    </Container>
  )
}

export default ArqPage
